import Header from "./header";

function closeMenu(e) {
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
}


function showMenu(e) {
  
  
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
  
}

export default function Roadmap(storedState) {
  return (
    <div>
    <Header/>
      <div>
        <div className="container mx-auto w-full h-full">
          <div className="relative wrap overflow-hidden p-10 my-10 h-full">
            <div className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border left-1/2"/>
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-gray-800  w-8 h-8 rounded-full">
                <h1 className="mx-auto font-semibold text-lg figma-shadow text-white">0</h1>
              </div>
              <div className="order-1 bg-white figma-shadow rounded-lg  w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-gray-800 text-xl">Phase 0 (Pre-Launch)</h3>
                <p className="text-sm leading-snug font-medium tracking-wide text-gray-900 text-opacity-100">
                    Create art traits and generate collection<br/>
                    Create the site for minting</p>
              </div>
            </div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-gray-800 w-8 h-8 rounded-full">
                <h1 className="mx-auto text-white font-semibold figma-shadow text-lg">1</h1>
              </div>
              <div className="order-1 bg-gray-500 rounded-lg figma-shadow w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-white text-xl">Phase 1 (Mint)</h3>
                <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
                  Publish the collection<br/>
                  Minting process<br/>
                  Run some giveaways<br/>
                  96 Gators goes to giveaways and creators reserve
                  Partnership with others projects</p>
              </div>
            </div>
            
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-gray-800 w-8 h-8 rounded-full">
                <h1 className="mx-auto font-semibold text-lg figma-shadow text-white">2</h1>
              </div>
              <div className="order-1 bg-white figma-shadow rounded-lg w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-gray-800 text-xl">Phase 2 (End of minting)</h3>
                <p className="text-sm leading-snug font-medium tracking-wide text-gray-900 text-opacity-100">
                  Gallery launch<br/>
                  10% from value of minting goes to a charity institution<br/>  
                  1000 FTM will be gived for 3 rarest Gators (500 FTM, 300 FTM, 200 FTM respectively)<br/>
                  30% of minting profit goes to Community Vault for staking in phase 3<br/>
                  80% of secondary sells royalties goes to Community Vault for staking<br/>
                  Rarity sheet publication<br/>
                </p>
              </div>
            </div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-5/12"></div>
              <div className="z-20 flex items-center order-1 bg-gray-800  w-8 h-8 rounded-full">
                <h1 className="mx-auto text-white font-semibold figma-shadow text-lg">3</h1>
              </div>
              <div className="order-1 bg-gray-500 rounded-lg figma-shadow w-5/12 px-6 py-4">
                <h3 className="mb-3 font-bold text-white text-xl">Phase 3 (Staking)</h3>
                <p className="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
                  Staking launch<br/>
                  NFT Staking for PXG Token<br/>
                  Pixel Gators owners will be in whitelist for next drop<br/>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
