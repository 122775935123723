
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import config from './config/config.json'; // with import
import { fetchData } from './redux/data/dataActions';
import { useNavigate } from 'react-router-dom';


function closeMenu(e) {
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
}


function showMenu(e) {
  document.getElementById("menu-list").classList.toggle("hidden");
  document.getElementById("menu-hamburger").classList.toggle("hidden");
  
}

export default function Header() {
  const navigate = useNavigate();
  const [state, setState] = useState(null);
  const [account, setAccount] = useState("");
  const blockchain = useSelector((state) => state.blockchain);

	const dispatch = useDispatch();
	const [CONFIG, SET_CONFIG] = useState({});

	  const getConfig = async () => {
      SET_CONFIG(config);
    };
    useEffect(() => {
      getConfig();
     }, []);
    useEffect(() => {
      if(blockchain.account){
        setAccount(blockchain.account)
      }
      getData();
	  }, [blockchain.account]);

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
		  dispatch(fetchData(blockchain.account));
      setState(blockchain)
		}
	  };

  return (
    <div>
      <div className="relative bg-white hidden md:list-item md:w-90">
        <div className="max-w-8xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-end border-b border-b-black py-6 md:space-x-10 figma-text-shadow">
            <div className="flex justify-center lg:w-0 lg:flex-1" onClick={e=> {navigate('/',{storedState:state})}}>
              <a className="font-semibold figma-text-shadow text-2xl cursor-pointer trade-mark">Pixel Gators</a>
            </div>
            <div className="flex justify-center lg:w-0 lg:flex-1 figma-text-shadow font-medium cursor-pointer transition duration-300 transform hover:-translate-y-1 hover:scale-110 " onClick={e=> {navigate('/gators',{storedState:state})}}>
            {/* <Link to={{pathname: '/gators', state: state}} >My gators</Link> */}
              <a>My gators</a>
            </div>
            {/* <div className="flex justify-center lg:w-0 lg:flex-1 figma-text-shadow font-medium cursor-pointer transition duration-300 transform hover:-translate-y-1 hover:scale-110 " onClick={e=> {navigate('/gallery',{storedState:state})}}>
              <a>Gallery</a>
            </div> */}
            {/* <div className="flex justify-center lg:w-0 lg:flex-1 figma-text-shadow font-medium cursor-pointer transition duration-300 transform hover:-translate-y-1 hover:scale-110 " onClick={e=> {navigate('/stake',{storedState:state})}}>
              <a>Stake</a>
            </div> */}
			<div className="flex justify-center lg:w-0 lg:flex-1 figma-text-shadow font-medium cursor-pointer transition duration-300 transform hover:-translate-y-1 hover:scale-110 " onClick={e=> {navigate('/roadmap',{storedState:state})}}>
              <a>Roadmap</a>
            </div>
            <div className="flex justify-center lg:w-0 lg:flex-1 cursor-pointer">
              <button className="bg-gradient-to-r figma-shadow from-blue-600 to-blue-900 text-white font-medium py-2 px-4 rounded-lg inline-flex items-center transition duration-300 transform hover:-translate-y-1 hover:scale-110 hover:from-blue-500 hover:to-blue-800 "
               onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}>
                <img
                  className="w-8"
                  src="https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg"
                />{
					blockchain.account == "" ||
                blockchain.smartContract == null || blockchain.account == null ? <span> Connect wallet</span> : <span> {account.substr(0,4)+"..."+account.substr(account.length - 5, 5)}</span> }
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="relative bg-white  md:hidden md:w-90">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="justify-between items-end border-b-2 border-gray-100 py-6 md:space-x-10">
            <div className="lg:hidden">
              <div className="menu" id="menu-hamburger" onClick={(e) => showMenu(e)}>
                <button className="navbar-burger flex items-end text-blue-600 p-3 cursor-pointer">
                  <svg
                    className="block h-4 w-4 fill-current"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                  </svg>
                </button>
              </div>
            </div>

            <div className="list hidden transition ease-in-out delay-150" id="menu-list">
              <button className="navbar-close" onClick={(e) => closeMenu(e)}>
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <div>
              <ul>
                
              <li className="mb-1 cursor-pointer" onClick={e=> {navigate('/',{storedState:state})}}>
                  <a
                    className="block p-4 text-sm font-semibold figma-text-shadow font-medium hover:bg-blue-50 hover:text-blue-600 rounded"
                  >
                    Pixel Gators
                  </a>
                </li>
                <li className="mb-1 cursor-pointer" onClick={e=> {navigate('/gators',{storedState:state})}}>
                  <a
                    className="block p-4 text-sm font-semibold figma-text-shadow font-medium hover:bg-blue-50 hover:text-blue-600 rounded"
                  >
                    My Gators
                  </a>
                </li>
                {/* <li className="mb-1 cursor-pointer" onClick={e=> {navigate('/gallery',{storedState:state})}}>
                  <a
                    className="block p-4 text-sm font-semibold figma-text-shadow font-medium hover:bg-blue-50 hover:text-blue-600 rounded"
                  >
                    Gallery
                  </a>
                </li> */}
                {/* <li className="mb-1 cursor-pointer" onClick={e=> {navigate('/stake',{storedState:state})}}>
                  <a
                    className="block p-4 text-sm font-semibold figma-text-shadow font-medium hover:bg-blue-50 hover:text-blue-600 rounded"
                  >
                    Stake
                  </a>
                </li> */}
                <li className="mb-1 cursor-pointer" onClick={e=> {navigate('/roadmap',{storedState:state})}}>
                  <a
                    className="block p-4 text-sm font-semibold figma-text-shadow font-medium hover:bg-blue-50 hover:text-blue-600 rounded"
                  >
                    Roadmap
                  </a>
                </li>
                <li className="mb-1 cursor-pointer" onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                }}>
                <a className="flex justify-between p-4 text-sm font-semibold figma-text-shadow font-medium bg-gradient-to-r figma-shadow from-blue-600 to-blue-900 rounded">
                  { blockchain.account === "" || blockchain.smartContract === null ? <span className='flex self-center'> Connect wallet</span> : <span className='flex self-center'> {account.substr(0,8)+"..."+account.substr(account.length - 8, 8)}</span> }
                  <img
                  className="w-8"
                  src="https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg"
                />
                </a>
                
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
